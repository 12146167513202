import { Controller } from '@hotwired/stimulus'
import TomSelect from 'tom-select/src/tom-select.popular'
import { TomInput, TomOption } from 'tom-select/src/types'
import { escape_html } from 'tom-select/src/utils'

interface UserTomOption extends TomOption {
  color: string
  text: string
}

export default class extends Controller {
  plugin: TomSelect

  connect (): void {
    this.initTomSelect()
  }

  private initTomSelect (): void {
    this.plugin = new TomSelect(
      this.element as TomInput, {
        render: {
          option: (data: UserTomOption, escape: typeof escape_html) => (
            `<div class="option-flex"><div class="h-5 w-5 shadow rounded ${data.color}"></div><div>${escape(data.text)}</div></div>`
          ),
          item: (data: UserTomOption, escape: typeof escape_html) => (
            `<div class="item-flex"><div class="h-5 w-5 shadow rounded ${data.color}"></div><div class="text-gray-800 dark:text-gray-200">${escape(data.text)}</div></div>`
          )
        }
      }
    )
  }
}
