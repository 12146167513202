import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['note', 'error']
  static values = {
    existedNote: String,
    newNote: String,
    relatedId: String,
    validationRegex: String
  }

  declare existedNoteValue: string
  declare newNoteValue: string
  declare relatedIdValue: string
  declare validationRegexValue: RegExp
  declare noteTarget: HTMLElement
  declare errorTarget: HTMLElement

  isNumber (value: string): boolean {
    return (/^[1-9]\d*$/).test(value)
  }

  isEmail (value: string): boolean {
    const regex = new RegExp(this.validationRegexValue)
    const match = value.match(regex)?.length ?? 0

    return match > 0
  }

  onOptionAdded (event: any): void {
    if (this.isEmail(event.detail.value)) {
      this.noteTarget.textContent = this.newNoteValue
      this.errorTarget.textContent = ''
      this.toggleRelatedSelect(event, 'show')
    } else {
      this.noteTarget.textContent = ''
      this.errorTarget.textContent = 'Wrong format. Please enter a valid value.'
    }
  }

  onOptionSelected (event: any): void {
    if (this.errorTarget?.textContent?.trim()?.length === 0) {
      this.noteTarget.textContent = this.isNumber(event.detail.value) ? this.existedNoteValue : this.newNoteValue
      this.errorTarget.textContent = ''

      if (!this.isNumber(event.detail.value)) {
        this.toggleRelatedSelect(event, 'show')
      }

      if (!this.isNumber(event.detail.value) && !this.isEmail(event.detail.value)) {
        this.noteTarget.textContent = ''
        this.errorTarget.textContent = 'Wrong format. Please enter a valid value.'
      }
    }
  }

  onOptionUnselected (event: any): void {
    this.noteTarget.textContent = ''
    this.errorTarget.textContent = ''
    this.toggleRelatedSelect(event, 'hide')
  }

  toggleRelatedSelect (event: any, action: string): void {
    if (this.relatedIdValue?.length > 0) {
      const element = event.currentTarget.closest('form').querySelector(`#${this.relatedIdValue}`)
      const select = element.querySelector('select')
      const classList = element.classList

      select.required = action === 'show' ? 'required' : ''

      if (action === 'hide') {
        classList.add('hidden')
      } else {
        classList.remove('hidden')
      }
    }
  }
}
