import { Controller } from '@hotwired/stimulus'
import DynamicSelect, { Options } from '../../modules/dynamic_select'

export default class extends Controller {
  static targets = [
    'name',
    'valueSelect',
    'valueInput',
    'valueCheckbox',
    'projectValueSelect',
    'projectValueInput',
    'projectValueCheckbox',
    'fromSender',
    'recipientsList',
    'ccRecipientsList',
    'bccRecipientsList',
    'recipient',
    'thresholdScore',
    'invitationInput',
    'fieldSelect',
    'fieldProjectSelect'
  ]

  static values = {
    nameOption: String,
    valueOption: String,
    values: Array,
    valueType: String,
    projectValues: Array,
    projectValueType: String
  }

  declare readonly nameTarget: HTMLInputElement
  declare readonly valueSelectTarget: HTMLSelectElement
  declare readonly valueInputTarget: HTMLInputElement
  declare readonly valueCheckboxTarget: HTMLInputElement
  declare readonly projectValueSelectTarget: HTMLSelectElement
  declare readonly projectValueInputTarget: HTMLInputElement
  declare readonly projectValueCheckboxTarget: HTMLInputElement
  declare readonly thresholdScoreTarget: HTMLInputElement
  declare readonly fromSenderTarget?: HTMLDivElement
  declare readonly hasFromSenderTarget: boolean
  declare readonly recipientsListTarget: HTMLDivElement
  declare readonly ccRecipientsListTarget: HTMLDivElement
  declare readonly bccRecipientsListTarget: HTMLDivElement
  declare readonly recipientTargets: HTMLDivElement[]
  declare readonly invitationInputTargets: HTMLInputElement[]
  declare readonly fieldSelectTarget: HTMLInputElement
  declare readonly fieldProjectSelectTarget: HTMLInputElement
  declare nameOptionValue: string
  declare valueOptionValue: string

  declare valuesValue: any[]
  declare valueTypeValue: string
  declare projectValuesValue: any[]
  declare projectValueTypeValue: string

  valueSelect: DynamicSelect
  projectValueSelect: DynamicSelect
  recipientTypeOptions: Options

  initialize (): void {
    this.valueSelect = new DynamicSelect(this.valueSelectTarget)
    this.projectValueSelect = new DynamicSelect(this.projectValueSelectTarget)

    setTimeout(() => {
      const event = new Event('change')
      this.nameTarget.dispatchEvent(event)
    })
  }

  updateOptions ({ detail: { options, recipientTypeOptions } }: { detail: { options: Options, recipientTypeOptions: Options } }): void {
    if (this.nameTarget.value === 'set_facility_field') {
      options = this.valuesValue
    }

    if (this.nameTarget.value === 'set_project_field') {
      options = this.projectValuesValue
    }

    this.toggleValueTarget(options)
    this.showThresholdScore()
    this.showFacilityField()
    this.showProjectField()
    this.toggleRecipients(recipientTypeOptions)
    this.toggleEmailFields(recipientTypeOptions)
    this.toggleInvitationInputs()
  }

  toggleValueTarget (options: Options): void {
    if (this.nameTarget.value === 'set_project_field') {
      this.projectValueSelect.reset(options)
    }
    this.valueSelect.reset(options)
    setTimeout(() => {
      const noValue = ['update_certification_status', 'set_scores', 'carry_forward'].includes(this.nameTarget.value)
      const isNonValueAction = ['carry_forward', 'set_scores'].includes(this.nameTarget.value)
      const showSelect = (this.valueTypeValue !== 'date') && (options.length > 0)
      const showProjectSelect = (this.projectValueTypeValue !== 'date') && (options.length > 0)

      if (this.nameTarget.value === 'set_project_field') {
        this.toggle(this.projectValueSelectTarget, !noValue && showProjectSelect)
        this.toggle(this.projectValueInputTarget, !noValue && !showProjectSelect && this.projectValueTypeValue !== 'boolean')
        this.toggle(this.projectValueCheckboxTarget, !noValue && this.projectValueTypeValue === 'boolean')
        this.showWideSelect(isNonValueAction)

        this.toggle(this.valueSelectTarget, false)
        this.toggle(this.valueInputTarget, false)
        this.toggle(this.valueCheckboxTarget, false)
      } else {
        this.toggle(this.valueSelectTarget, !noValue && showSelect)
        this.toggle(this.valueInputTarget, !noValue && !showSelect && this.valueTypeValue !== 'boolean')
        this.toggle(this.valueCheckboxTarget, !noValue && this.valueTypeValue === 'boolean')
        this.showWideSelect(isNonValueAction)

        this.toggle(this.projectValueSelectTarget, false)
        this.toggle(this.projectValueInputTarget, false)
        this.toggle(this.projectValueCheckboxTarget, false)
      }
    })
  }

  loadRecipients ({ target }: { target: HTMLSelectElement }): void {
    const event = this.buildRecipientToggleEvent(true)

    target.dispatchEvent(event)
  }

  updateValues ({ detail: { values, valueType } }: { detail: { values: any[], valueType: string } }): void {
    this.valuesValue = values
    this.valueTypeValue = valueType
    this.valueInputTarget.value = ''
    this.valueCheckboxTarget.checked = false
    this.toggleValueTarget(values)
  }

  projectUpdateValues ({ detail: { values, valueType } }: { detail: { values: any[], valueType: string } }): void {
    this.projectValuesValue = values
    this.projectValueTypeValue = valueType
    this.projectValueInputTarget.value = ''
    this.projectValueCheckboxTarget.checked = false
    this.toggleValueTarget(values)
  }

  valueTypeValueChanged (): void {
    this.setInputType(this.valueInputTarget, this.valueTypeValue)
  }

  projectValueTypeValueChanged (): void {
    this.setInputType(this.projectValueInputTarget, this.projectValueTypeValue)
  }

  private setInputType (inputTarget: HTMLInputElement, valueType: string): void {
    switch (valueType) {
      case 'float':
        inputTarget.type = 'number'
        break
      case 'datetime':
      case 'date':
        inputTarget.type = 'date'
        if (inputTarget.value.length === 0) {
          inputTarget.value = new Date().toISOString().slice(0, 10)
        }
        break
      default:
        inputTarget.type = 'text'
    }
  }

  private toggle (input: HTMLInputElement | HTMLSelectElement, show: boolean): void {
    input.disabled = !show

    if (input === this.valueCheckboxTarget) {
      this.toggleHiddenClass(this.valueCheckboxTarget.parentElement as HTMLInputElement, show)
    } else if (input === this.projectValueCheckboxTarget) {
      this.toggleHiddenClass(this.projectValueCheckboxTarget.parentElement as HTMLInputElement, show)
    } else {
      this.toggleHiddenClass(input, show)
    }
  }

  private toggleHiddenClass (el: HTMLElement, show: boolean): void {
    el.classList.toggle('hidden', !show)
  }

  private showWideSelect (show: boolean): void {
    if (this.nameTarget.parentElement != null) {
      this.nameTarget.classList.toggle('h-full', show)
      this.nameTarget.parentElement.classList.toggle('h-full', show)
    }
  }

  private showThresholdScore (): void {
    const enable = this.nameTarget.value === 'update_certification_status'

    this.toggle(this.thresholdScoreTarget, enable)
  }

  private toggleRecipients (types: Options): void {
    this.recipientTypeOptions = types
    const actions = ['send_email_notification_template', 'send_sms_notification_template', 'send_invitation']
    const enable = actions.includes(this.nameTarget.value)
    const event = this.buildRecipientToggleEvent(enable)

    this.toggleHiddenClass(this.recipientsListTarget, enable)
    this.recipientTargets.forEach(target => target.dispatchEvent(event))
  }

  private toggleEmailFields (types: Options): void {
    this.recipientTypeOptions = types
    const actions = ['send_email_notification_template']
    const enable = actions.includes(this.nameTarget.value)

    this.toggleHiddenClass(this.ccRecipientsListTarget, enable)
    this.toggleHiddenClass(this.bccRecipientsListTarget, enable)

    if (this.hasFromSenderTarget && this.fromSenderTarget !== undefined) {
      this.toggleHiddenClass(this.fromSenderTarget, enable)
    }
  }

  private toggleInvitationInputs (): void {
    const enable = this.nameTarget.value === 'send_invitation'

    this.invitationInputTargets.forEach(input => this.toggle(input, enable))
  }

  private buildRecipientToggleEvent (enable: boolean): CustomEvent {
    return new CustomEvent('recipient:toggle', { detail: { enable, types: this.recipientTypeOptions } })
  }

  private showFacilityField (): void {
    const enable = this.nameTarget.value === 'set_facility_field'

    this.toggle(this.fieldSelectTarget, enable)
  }

  private showProjectField (): void {
    const enable = this.nameTarget.value === 'set_project_field'

    this.toggle(this.fieldProjectSelectTarget, enable)
  }
}
