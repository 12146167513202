import { Controller } from '@hotwired/stimulus'
import TomSelect from 'tom-select/src/tom-select.popular'
import { TomInput, TomOption } from 'tom-select/src/types'
import { escape_html } from 'tom-select/src/utils'

interface UserTomOption extends TomOption {
  icon: string
  text: string
}

export default class extends Controller {
  plugin: TomSelect

  static values = {
    options: Array
  }

  declare optionsValue: any

  connect (): void {
    this.initTomSelect()
  }

  private initTomSelect (): void {
    this.plugin = new TomSelect(
      this.element as TomInput, {
        valueField: 'id',
        options: this.optionsValue,
        render: {
          option: (data: UserTomOption, escape: typeof escape_html) => (
            `<div class="option-flex"><div>${data.icon}</div><div>${escape(data.title)}</div></div>`
          ),
          item: (data: UserTomOption, escape: typeof escape_html) => (
            `<div class="item-flex"><div class="text-gray-800 dark:text-gray-200">${data.icon}</div><div class="text-gray-800 dark:text-gray-200">${escape(data.title)}</div></div>`
          )
        }
      }
    )
  }
}
