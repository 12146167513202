import { Controller } from '@hotwired/stimulus'

// this controller is used to count the number of checkboxes that are checked
// and display it in the target span element
export default class extends Controller {
  static targets = ['counter', 'checkbox']
  static values = {
    count: Number
  }

  declare readonly counterTarget: HTMLElement
  declare readonly checkboxTargets: HTMLInputElement[]
  declare countValue: number

  connect (): void {
    document.addEventListener('count-checkboxes:reset', () => { this.reset() })
    document.addEventListener('popstate', this.countChecked)

    this.countChecked()
  }

  disconnect (): void {
    document.removeEventListener('popstate', this.countChecked)
  }

  select ({ target }: { target: HTMLInputElement }): void {
    target.checked ? this.countValue++ : this.countValue--
  }

  private countValueChanged (): void {
    if (this.countValue > 0) {
      this.show(this.counterTarget)
    } else {
      this.hide(this.counterTarget)
    }
    this.counterTarget.innerText = this.countValue.toString()
  }

  private show (element: HTMLElement): void {
    element.classList.remove('hidden')
  }

  private hide (element: HTMLElement): void {
    element.classList.add('hidden')
  }

  private countChecked (): void {
    this.countValue = this.checkboxTargets.filter((checkbox: HTMLInputElement) => checkbox.checked).length
  }

  reset (): void {
    this.countValue = 0
  }
}
