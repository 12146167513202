import { Controller } from '@hotwired/stimulus'
import TomSelect from 'tom-select/src/tom-select.popular'
import { TomInput, TomOption } from 'tom-select/src/types'
import { escape_html } from 'tom-select/src/utils'
import removeButton from 'tom-select/src/plugins/remove_button/plugin'

TomSelect.define('remove_button', removeButton)

interface UserTomOption extends TomOption {
  avatar: string
  text: string
}

export default class extends Controller {
  plugin: TomSelect

  connect (): void {
    this.initTomSelect()
  }

  private initTomSelect (): void {
    this.plugin = new TomSelect(
      this.element as TomInput, {
        plugins: ['remove_button'],
        render: {
          option: (data: UserTomOption, escape: typeof escape_html) => (
            `<div><span class="pr-1">${data.avatar}</span> ${escape(data.text)}</div>`
          ),
          item: (data: UserTomOption, escape: typeof escape_html) => (
            `<div><span class="pr-1">${data.avatar}</span> ${escape(data.text)}</div>`
          )
        }
      }
    )
  }
}
