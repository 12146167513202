import { Controller } from '@hotwired/stimulus'

const defaultStatusFilter = 'all'

export default class extends Controller {
  static targets = ['rolesFilterCount', 'resetButton', 'role']
  static values = {
    rolesFilter: Array,
    statusFilter: String
  }

  declare readonly rolesFilterCountTarget: HTMLElement
  declare readonly resetButtonTarget: HTMLElement
  declare readonly roleTargets: HTMLInputElement[]
  declare rolesFilterValue: string[]
  declare statusFilterValue: string

  rolesFilter (): void {
    this.rolesFilterValue = this.roleTargets.filter((role) => role.checked).map((role) => role.value)
  }

  statusFilter ({ params: { statusFilter } }: { params: { statusFilter: string } }): void {
    this.statusFilterValue = statusFilter
  }

  private rolesFilterValueChanged (): void {
    this.rolesFilterCountTarget.textContent = this.rolesFilterValue.length.toString()
    this.updateButtonState()
  }

  private statusFilterValueChanged (): void {
    this.updateButtonState()
  }

  private updateButtonState (): void {
    const hideResetButton = this.statusFilterValue === defaultStatusFilter && this.rolesFilterValue.length === 0

    this.resetButtonTarget.classList.toggle('hidden', hideResetButton)
  }
}
