import { Controller } from '@hotwired/stimulus'
import BulkSelectController from './../bulk_select_controller'

export default class extends Controller {
  static targets = ['messageTag']
  static outlets = ['bulk-select']

  static values = {
    businessProcessName: String,
    newProjectsGroupedByFacility: Boolean
  }

  declare readonly hasMessageTagTarget: boolean
  declare readonly messageTagTarget?: HTMLElement
  declare readonly hasBulkSelectOutlet: boolean
  declare readonly bulkSelectOutlet?: BulkSelectController
  declare readonly businessProcessNameValue: string
  declare readonly newProjectsGroupedByFacilityValue: boolean

  connect (): void {
    const facilitiesCount = this.hasBulkSelectOutlet && this.bulkSelectOutlet !== undefined ? this.bulkSelectOutlet.countValue : 1
    const companiesCount = this.hasBulkSelectOutlet ? this.calculateCompaniesCount() : 1

    if (this.hasMessageTagTarget && this.messageTagTarget !== undefined) {
      this.messageTagTarget.textContent = this.buildMessage(facilitiesCount, companiesCount)
    }
  }

  updateCreationMessage ({ target }: { target: HTMLSelectElement }): void {
    if (this.hasMessageTagTarget && this.messageTagTarget !== undefined) {
      this.messageTagTarget.textContent = this.buildMessage(target.selectedOptions.length, 1)
    }
  }

  private calculateCompaniesCount (): number {
    if (this.bulkSelectOutlet === undefined) {
      return 1
    }

    const selectedCompanyIds = new Set(
      this.bulkSelectOutlet.checkboxTargets
        .filter((checkbox: HTMLInputElement) => checkbox.checked)
        .map((checkbox: HTMLInputElement) => checkbox.dataset.companyId)
    )

    return selectedCompanyIds.size
  }

  private buildMessage (facilitiesCount: number, companiesCount: number): string {
    const newProjectsNumber = this.newProjectsGroupedByFacilityValue ? facilitiesCount : companiesCount

    return `${newProjectsNumber} ${this.businessProcessNameValue}${newProjectsNumber > 1 ? 's' : ''} will be created`
  }
}
