import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['active', 'archived', 'activeButton', 'archivedButton']

  declare readonly activeTargets: HTMLInputElement[]
  declare readonly archivedTargets: HTMLInputElement[]
  declare readonly activeButtonTargets: HTMLButtonElement[]
  declare readonly archivedButtonTargets: HTMLButtonElement[]

  toggleButtons (): void {
    const active = Array.from(this.activeTargets).find(input => input.checked) !== undefined
    const archived = Array.from(this.archivedTargets).find(input => input.checked) !== undefined

    this.toggle(this.activeButtonTargets, active && !archived)
    this.toggle(this.archivedButtonTargets, archived && !active)
  }

  private toggle (buttons: HTMLButtonElement[], condition: boolean): void {
    if (condition) {
      buttons.forEach(this.show)
    } else {
      buttons.forEach(this.hide)
    }
  }

  private show (element: HTMLElement): void {
    element.classList.remove('hidden')
  }

  private hide (element: HTMLElement): void {
    element.classList.add('hidden')
  }
}
