import { Controller } from '@hotwired/stimulus'
import SignaturePad from 'signature_pad'

const options = { backgroundColor: 'rgb(255, 255, 255)' }

export default class extends Controller<HTMLFormElement> {
  static targets = ['canvas', 'signature']

  declare readonly canvasTarget: HTMLCanvasElement
  declare readonly signatureTarget: HTMLInputElement
  declare readonly hasCanvasTarget: boolean
  declare readonly hasSignatureTarget: boolean

  signaturePad: SignaturePad

  initialize (): void {
    if (this.hasCanvasTarget) {
      this.signaturePad = new SignaturePad(this.canvasTarget, options)
    }
  }

  submit ({ target }: { target: HTMLButtonElement }): void {
    if (this.hasSignatureTarget) {
      if (this.signaturePad.isEmpty()) {
        return alert('Please provide a signature.')
      }

      this.signatureTarget.value = this.signaturePad.toDataURL('image/png')
    }

    const event = new CustomEvent('project-transition-form:submit')
    target.dispatchEvent(event)
  }

  clear (): void {
    this.signaturePad.clear()
  }
}
