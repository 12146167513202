import { Controller } from '@hotwired/stimulus'
import flatpickr from 'flatpickr'

interface FlatpickrInputElement extends HTMLInputElement {
  _flatpickrInstance?: flatpickr.Instance
}

export default class extends Controller {
  static targets = ['input', 'projectInput']

  declare readonly inputTarget: FlatpickrInputElement
  declare readonly projectInputTarget: FlatpickrInputElement
  declare readonly hasProjectInputTarget: boolean

  connect (): void {
    this.update()
  }

  disconnect (): void {
    this.destroyDatepicker(this.inputTarget)
  }

  update (): void {
    setTimeout(() => {
      const inputTypes = ['date', 'hidden', 'datetime-local', 'datetime']
      // Handle inputTarget
      if (inputTypes.includes(this.inputTarget.type)) {
        this.initializeDatepicker(this.inputTarget)
      } else if (this.inputTarget._flatpickrInstance != null) {
        this.destroyDatepicker(this.inputTarget)
      }
      // Handle projectInputTarget
      if (this.hasProjectInputTarget) {
        if (inputTypes.includes(this.projectInputTarget.type)) {
          this.initializeDatepicker(this.projectInputTarget)
        } else if (this.projectInputTarget._flatpickrInstance != null) {
          this.destroyDatepicker(this.projectInputTarget)
        }
      }
    }, 10)
  }

  initializeDatepicker (inputElement: FlatpickrInputElement): void {
    const enableTime = inputElement.dataset.datepickerEnableTime === 'true'
    inputElement.dataset.originalType = inputElement.type
    let dateFormat = 'Y-m-d'
    let altFormat = 'd/m/Y'

    if (enableTime) {
      dateFormat += ' H:i'
      altFormat += ' H:i'
    }

    inputElement._flatpickrInstance = flatpickr(inputElement, {
      enableTime,
      dateFormat,
      altFormat,
      altInput: true,
      allowInput: true,
      monthSelectorType: 'static',
      altInputClass: `${inputElement.className} flatpickr-input`,
      minDate: inputElement.min,
      static: true
    })
  }

  destroyDatepicker (inputElement: FlatpickrInputElement): void {
    const datepicker = inputElement._flatpickrInstance

    if (datepicker != null) {
      datepicker.destroy()
      delete inputElement._flatpickrInstance

      inputElement.value = ''
      inputElement.type = inputElement.dataset.originalType !== undefined ? inputElement.dataset.originalType : 'text'
      inputElement.nextElementSibling?.remove()
    }
  }
}
