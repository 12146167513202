import { Controller } from '@hotwired/stimulus'
import TomSelect from 'tom-select/src/tom-select.popular'
import { TomInput } from 'tom-select/src/types'
import removeButton from 'tom-select/src/plugins/remove_button/plugin'
import virtualScroll from 'tom-select/src/plugins/virtual_scroll/plugin'
import checkboxOptions from 'tom-select/src/plugins/checkbox_options/plugin'

TomSelect.define('checkbox_options', checkboxOptions)
TomSelect.define('remove_button', removeButton)
TomSelect.define('virtual_scroll', virtualScroll)

export default class extends Controller {
  static targets = ['facilitiesWrapper']
  static values = {
    organizationId: String
  }

  declare readonly facilitiesWrapperTarget: HTMLInputElement
  declare organizationIdValue: string

  currentPage = 1
  nothingMoreToLoad = false

  plugin: TomSelect

  connect (): void {
    this.initTomSelect()

    this.plugin.on('focus', () =>
      setTimeout(() => this.selectDropdownPosition(), 0)
    )
    this.plugin.on('type', () => this.selectDropdownPosition())
    this.plugin.on('load', () => this.selectDropdownPosition())

    this.plugin.dropdown_content.addEventListener('scroll', () => {
      const dropdown = this.plugin.dropdown_content

      if (
        dropdown.scrollTop + dropdown.clientHeight < dropdown.scrollHeight ||
        this.nothingMoreToLoad
      ) {
        return
      }

      this.loadData(this.plugin.lastQuery, (data: any[]) => {
        this.plugin.addOptions(data)
        this.plugin.refreshOptions(false)
      })
    })
  }

  private initTomSelect (): void {
    this.plugin = new TomSelect(
      this.facilitiesWrapperTarget.querySelector('select') as TomInput,
      {
        valueField: 'id',
        labelField: 'name',
        searchField: ['name'],
        sortField: 'name',
        plugins: ['checkbox_options', 'remove_button', 'virtual_scroll'],
        hidePlaceholder: true,
        maxOptions: 99999,
        preload: true,
        render: {
          option: (data: { name: string }) => `<div><p>${data.name}</p></div>`,
          item: (data: { name: string }) => `<div><p>${data.name}</p></div>`
        },
        firstUrl: () => {},
        shouldLoadMore: () => !this.nothingMoreToLoad,
        onDropdownOpen: this.toggleContainerOverflow,
        onBlur: this.toggleContainerOverflow,
        load: this.loadData.bind(this),
        onType: (value: string) => {
          this.plugin.clearOptions()
          this.currentPage = 1
          this.nothingMoreToLoad = false

          if (value === '') this.plugin.load('')
        }
      }
    )
  }

  private hasNoOrganizationId (): boolean {
    return this.organizationIdValue === ''
  }

  loadData (query: string, callback: (data: any[]) => void): void {
    if (query === null) return
    if (this.hasNoOrganizationId()) {
      const data: any[] = []
      callback(data)
      return
    }

    const url =
      `/facilities?page=${this.currentPage}&q[organization_id_eq]=${this.organizationIdValue}` +
      (query === '' ? '' : `&q[name_cont]=${encodeURIComponent(query)}`)

    const oldScrollTop = this.plugin.dropdown_content.scrollTop

    fetch(url, {
      headers: {
        Accept: 'application/json'
      }
    })
      .then(async (response) => await response.json())
      .then((data) => {
        callback(data)

        if (data.length > 0) this.currentPage++
        this.nothingMoreToLoad = data.length === 0

        requestAnimationFrame(() => {
          this.plugin.dropdown_content.scrollTop = oldScrollTop
        })
      })
      .finally(() => this.plugin.trigger('no_results'))
  }

  toggleContainerOverflow (): void {
    document
      .querySelectorAll('.overflow-y-auto, .overflow-y-hidden')
      .forEach((element) =>
        element.dispatchEvent(new Event('overflow-toggle'))
      )
  }

  changeCompany ({ target }: { target: HTMLInputElement }): void {
    this.organizationIdValue = target.value
  }

  private organizationIdValueChanged (): void {
    const isHidden: boolean = this.hasNoOrganizationId()

    this.facilitiesWrapperTarget.classList.toggle('hidden', isHidden)

    if (isHidden || this.plugin === undefined) return

    this.plugin.clear(true)
    this.plugin.clearOptions()

    this.currentPage = 1
    this.nothingMoreToLoad = false

    this.plugin.load('')
  }

  selectDropdownPosition (): void {
    const dropdown = this.plugin.dropdown
    const inputRect = this.plugin.control_input.getBoundingClientRect()
    const spaceBelow = window.innerHeight - inputRect.bottom

    dropdown.style.top =
      spaceBelow > 260 ? '' : `-${dropdown.clientHeight + 10}px`
  }
}
