import { Controller } from '@hotwired/stimulus'
import { useMatchMedia } from 'stimulus-use'

const htmlElement = document.documentElement

interface MediaQueryChangeEvent {
  matches: boolean
}

export default class extends Controller {
  declare isPrinting: boolean

  connect (): void {
    useMatchMedia(this, {
      mediaQueries: {
        systemPreference: '(prefers-color-scheme: dark)'
      }
    })

    if (htmlElement.classList.contains('system')) {
      this.systemPreferenceChanged(window.matchMedia('(prefers-color-scheme: dark)'))
    }

    window.addEventListener('beforeprint', () => {
      this.isPrinting = true
    })

    window.addEventListener('afterprint', () => {
      this.isPrinting = false
    })
  }

  // This will be called whenever the media query '(prefers-color-scheme: dark)' changes
  systemPreferenceChanged ({ matches }: MediaQueryChangeEvent): void {
    if (this.isPrinting) {
      return
    }

    if (matches) {
      // The user prefers a dark theme
      htmlElement.classList.remove('light', 'system')
      htmlElement.classList.add('dark')
    } else {
      // The user prefers a light theme
      htmlElement.classList.remove('dark', 'system')
      htmlElement.classList.add('light')
    }
  }
}
