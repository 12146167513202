import { Dropdown } from 'tailwindcss-stimulus-components'

export default class extends Dropdown {
  static targets = ['menu']
  static values = { open: Boolean, adaptive: Boolean }

  declare menuTarget: HTMLElement
  declare adaptiveValue: boolean
  declare openValue: boolean

  toggle (): void {
    super.toggle()
    this.adjustDropdown()
  }

  hide (e: Event): void {
    // Fix for file input in Trix editor
    if ((e.target as HTMLElement).tagName === 'INPUT' && (e.target as HTMLInputElement).type === 'file') {
      return
    }

    super.hide(e)
    this.adjustDropdown()
  }

  closeWithTimeout (): void {
    setTimeout(() => {
      super.close()
    }, 100)
  }

  private adjustDropdown (): void {
    if (this.adaptiveValue) {
      if (this.openValue) {
        this.update()
      } else {
        this.reset()
      }
    }

    this.rowZIndex()
  }

  private update (): void {
    setTimeout(() => {
      const dropdownRect = this.menuTarget.getBoundingClientRect()

      this.adjustForTable(dropdownRect)
      this.adjustForPage(dropdownRect)
    }, 0)
  }

  private adjustForTable (dropdownRect: DOMRect): void {
    const table = this.menuTarget.closest('table')

    if (table != null) {
      const tableRect = table.getBoundingClientRect()
      const spaceBelow = tableRect.bottom - dropdownRect.bottom
      const spaceAbove = dropdownRect.top - tableRect.top

      if (spaceBelow < dropdownRect.height && spaceAbove > dropdownRect.height * 1.5) {
        this.menuTarget.style.top = `-${dropdownRect.height + 8}px`
      }
    }
  }

  private adjustForPage (dropdownRect: DOMRect): void {
    const spaceBelow = window.innerHeight - dropdownRect.bottom
    const spaceAbove = dropdownRect.top

    if (spaceBelow < dropdownRect.height && spaceAbove > dropdownRect.height) {
      this.menuTarget.style.top = `-${dropdownRect.height + 8}px`
    }
  }

  private rowZIndex (): void {
    const tr = this.menuTarget.closest('tr')
    const td = this.menuTarget.closest('td')

    td != null && (td.style.zIndex = this.openValue ? '50' : '')
    tr != null && (tr.style.zIndex = this.openValue ? '50' : '')
  }

  private reset (): void {
    this.menuTarget.classList.add('hidden')
    this.menuTarget.style.top = ''
  }
}
